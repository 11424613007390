<template>
  <b-modal :visible="show" :hide-header="true" no-close-on-backdrop centered id="modal-melhor-oferta"
    size="lg" :hide-footer="true">
    <!-- <div class="float-right"><img @click="hide" class="close" src="img/x-icon.svg" /></div> -->
    <div class="row text-center">
      <div class="col-sm-12 col-12 mt-2">
        <h2 class="bold title-cotacao mb-5 mt-2">Melhor oferta é o seguro anual 🎉</h2>

        <h4 class="mb-4">{{ nome }}, tem certeza que deseja contratar o seguro mensal e perder o desconto
          do seguro anual?</h4>

        <div class="center-modal mt-5">
          <button class="ghost mr-3 mb-2" @click.prevent="hide">Seguir sem o desconto</button>
          <button class="continue-btn-modal mb-2" @click.prevent="want"> Quero o desconto!</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import config from "../../config";
import formatacaoMoeda2 from "../../helpers/formatacaoMoeda2";
export default {
  props: ["show", "nome"],
  data() {
    return { config, formatacaoMoeda2 };
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    want() {
      this.$emit("want");
    },

  },
};
</script>

<style>
.bold {
  font-weight: bold;
}

.ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: #FFFFFF;
  color: #78849E;
  text-wrap: nowrap;
}

.continue-btn-modal {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4FA935;
  border-radius: 8px;
  background: #4FA935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.center-modal {
  text-align: center;
}

#modal-cotacao___BV_modal_content_ {
  border-radius: 12px !important;
  padding: 25px;
  padding-top: 15px;
}

.close {
  width: 15px;
  cursor: pointer;
}

.vw-20 {
  width: 20vw;
}

.voltar {
  color: #9a9a9a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.confirm {
  font-size: 18px;
  padding: 12px 20px 12px 20px;
  border-radius: 12px;
  border: 1px solid #4FA935;
  background: #4FA935;
  color: #fff;
}

.title-cotacao {
  color: #425994;
}

.sub-title-cotacao {
  color: #78849E;
}



@media (max-width: 768px) {

  .sub-title {
    font-size: 17px;
  }

  .title {
    font-size: 20px;
  }

  .vw-m-60 {
    width: 60vw;
  }

  #modal-cotacao___BV_modal_content_ {
    padding: 0 !important;
    padding-top: 0 !important;
  }
}
</style>
